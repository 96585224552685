 


<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="name">Name</label>
          <vs-input
            data-vv-validate-on="blur"
            v-validate="'required'"
            name="name"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            v-model="dealerDetail.name"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('name') }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="fullAddress">Display Address</label>
          <gmap-autocomplete
            id="autocomplete"
            class="w-full"
            :options="autocompleteOptions"
            style="
              padding: 0.7rem !important;
              border: 1px solid rgba(0, 0, 0, 0.2) !important;
              border-radius: 5px;
            "
            placeholder="Enter your address"
            name="fullAddress"
            
            @place_changed="setPlace"
          ></gmap-autocomplete>
          <span v-if="addressErrorMessage" class="text-danger text-sm">{{
            addressErrorMessage
          }}</span>
        </div>
      </vs-col>
      
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="licenseNumber">License Number</label>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="licenseNumber"
            icon-no-border
            icon="icon icon-edit"
            icon-pack="feather"
            v-model="dealerDetail.licenseNumber"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('licenseNumber') }}</span>
        </div>
      </vs-col>
      
      <vs-divider position="left" color="danger">Contact Information</vs-divider>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="email">Contact Email</label>
          <vs-input
            v-validate="'required|email|min:3'"
            data-vv-validate-on="blur"
            name="email"
            icon-no-border
            icon="icon icon-mail"
            icon-pack="feather"
            v-model="dealerDetail.contactInformation.email"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('email') }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="contactName">Contact Name</label>
          <vs-input
            data-vv-validate-on="blur"
            v-validate="'required'"
            name="contactName"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            v-model="dealerDetail.contactInformation.contactName"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('contactName') }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="phoneNumber">Contact Mobile Number</label>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="phoneNumber"
            icon-no-border
            icon="icon icon-phone"
            icon-pack="feather"
            v-model="dealerDetail.contactInformation.contactPhone"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('phoneNumber') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="telephoneNumber">Contact TelePhone Number</label>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="telephoneNumber"
            icon-no-border
            icon="icon icon-phone"
            icon-pack="feather"
            v-model="dealerDetail.contactInformation.contactTelePhone"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('telephoneNumber') }}</span>
        </div>
      </vs-col>
      <vs-row vs-justify="flex-end">
        <vs-button color="success" v-if="dealerDetail._id" @click="updateDealerDetail">Update</vs-button>
        <vs-button color="success" v-if="!dealerDetail._id" @click="addNewDealerDetail">Save</vs-button>
        <vs-button
          color="danger"
          style="margin-left:5px;"
          @click="$router.replace('/admin/car-dealer')"
        >Cancel</vs-button>
      </vs-row>
    </vs-row>
  </vx-card>
</template>


<script>
import { mapActions } from "vuex";

export default {
  name: "dealerDetails",

  data: () => ({
    activeTab: 0,
    autocompleteOptions: {
      componentRestrictions: {
        country: [
        'AU',
      ],
      },
    },
    addressErrorMessage: null,
    dealerDetail: {
      contactInformation: {
      },
      location: {},
      displayAddress: ''
    },
  }),
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.dealerDetail.licenseNumber != "" &&
        this.dealerDetail.name != "" &&
        this.dealerDetail.contactNumber != "" &&
        this.dealerDetail.displayAddress !==""
      );
    },
  },
  methods: {
    ...mapActions("carDealer", ["fetchCarDealerDetail", "addNewCarDealer", "updateCarDealerDetails"]),
    getDealerDetail(id) {
      let self = this;
      this.fetchCarDealerDetail(id).then((res) => {
        this.dealerDetail = res.data.data;
        document.getElementById("autocomplete").value = this.dealerDetail.displayAddress;
      });
    },
    updateDealerDetail() {
      let isFormValid = true;
      if (!document.getElementById("autocomplete").value) {
        isFormValid = false;
        this.addressErrorMessage = "The address field is required";
      }
      this.$validator.validateAll().then((result) => {
        if (result && isFormValid) {
          this.updateCarDealerDetails(this.dealerDetail).then((res) => {
            this.$vs.notify({
              title: "Car Dealer Updated",
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.$router.push(`/admin/car-dealer/${this.dealerDetail._id}`);
          });
        }
      })
    },

    addNewDealerDetail() {
      let isFormValid = true;
      if (!document.getElementById("autocomplete").value) {
        isFormValid = false;
        this.addressErrorMessage = "The address field is required";
      }
      this.$validator.validateAll().then((result) => {
        if (result && isFormValid) {
          this.addNewCarDealer(this.dealerDetail).then((res) => {
            
            if (res.data.data) {
              this.$vs.notify({
                title: "CarDealer Added",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.$router.push(
                `/admin/car-dealer/${res.data.data._id}`
              );
              this.getDealerDetail(res.data.data._id);
            } else {
              this.$vs.notify({
                title: "Error",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            }
          });
        }
      });
    },

    setPlace(place) {
      this.addressErrorMessage = ""
      this.dealerDetail.fullAddress = place;
      if(this.dealerDetail.fullAddress) {
         this.dealerDetail.location.coordinates = [
          this.dealerDetail.fullAddress.geometry.location.lng(),
          this.dealerDetail.fullAddress.geometry.location.lat(),
          ];
      }
      this.dealerDetail.fullAddress = ""
      this.dealerDetail.displayAddress = document.getElementById("autocomplete").value;
    },
  },
  created() {
    let dealerId = this.$route.params.id;
    if (dealerId) {
      this.getDealerDetail(dealerId);
    }
  },
};
</script>
<style>
.ql-editor {
  min-height: 200px;
}
</style>  